import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';

import './Footer.css'
import { Col, Container, Row } from 'react-bootstrap';

type FooterProps = {
    marginTop?: boolean;
    i18nNamespace: string;
}

function Footer(props: FooterProps) {
    const { i18nNamespace } = props;
    const { t } = useTranslation(i18nNamespace);

    if (props.marginTop) {
        return (
            <footer className="footer-margin-top">
                <Container>
                    <Row>
                        <Col>
                            <p dangerouslySetInnerHTML={{
                                __html: t('footer.message', {
                                    year: new Date().getFullYear().toString()
                                }) || ''
                            }} />
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }

    return (
        <footer>
            <Container>
                <Row>
                    <Col>
                        <p dangerouslySetInnerHTML={{
                            __html: t('footer.message', {
                                year: new Date().getFullYear().toString()
                            }) || ''
                        }} />
                    </Col>
                </Row>
             </Container>
        </footer>
    )
}

const mapStateToProps = state => ({
    i18nNamespace: state.main.i18nConfiguration.namespace
})

export default connect(mapStateToProps)(Footer)
