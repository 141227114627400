import * as t from "../types";

import { i18nConfiguration, i18nDefaultConfiguration } from "src/configuration";

import { UserManagementDomainEntitiesPasswordComplexity } from "src/services/gdp-iam-backend/models";

export interface MainState {
    stateToken: string
    factorId: string
    contactValue: string
    otpPassCode: any
    authRequest: any
    userId: string
    onboardStep: string
    i18nConfiguration: i18nConfiguration
    selfOnboard: SelfOnboardState
    campaignOnboard: CampaignOnboardState
    businessUnit: string
}

export interface SelfOnboardState {
    stateToken: string;
    email: string;
    phoneNumber: string;
    app: string;
}

export interface CampaignOnboardState {
    clientId: string
    customerOnboardRequestId: string
    passwordComplexity?: UserManagementDomainEntitiesPasswordComplexity;
}

const initialState: MainState = {
    stateToken: '',
    factorId: '',
    contactValue: '',
    otpPassCode: [],
    authRequest: '',
    userId: '',
    onboardStep: '',
    i18nConfiguration: i18nDefaultConfiguration,
    selfOnboard: {
        stateToken: '',
        email: '',
        phoneNumber: '',
        app: ''
    },
    campaignOnboard: {
        clientId: '',
        customerOnboardRequestId: ''
    },
    businessUnit: ''
};

const main = (state = initialState, action) => {
    switch (action.type) {
        case t.SET_STATETOKEN:
            return {
                ...state,
                stateToken: action.payload.stateToken
            };
        case t.SET_FACTORID:
            return {
                ...state,
                factorId: action.payload.factorId
            };
        case t.SET_CONTACT_VALUE:
            return {
                ...state,
                contactValue: action.payload.contactValue
            };
        case t.SET_OTP_CODE:
            return {
                ...state,
                otpPassCode: action.payload.otp
            };
        case t.SET_AUTH_REQ:
            return {
                ...state,
                authRequest: action.payload.authnRequest
            };
        case t.SET_USER_ID:
            return {
                ...state,
                userId: action.payload.userId
            }
        case t.SET_ONBOARD_STEP:
            return {
                ...state,
                onboardStep: action.payload.onboardStep
            }
        case t.SET_I18N_CONFIGURATION:
            return {
                ...state,
                i18nConfiguration: action.payload.i18nConfiguration
            }
        case t.SET_SELF_ONBOARD_DATA:
            return {
                ...state,
                selfOnboard: {
                    ...state.selfOnboard,
                    ...action.payload
                }
            };
        case t.SET_CAMPAIGN_ONBOARD_INIT:
            return {
                ...state,
                campaignOnboard: {
                    clientId: action.payload.clientId,
                    customerOnboardRequestId: action.payload.customerOnboardRequestId,
                    passwordComplexity: action.payload.passwordComplexity
                }
            }
        case t.SET_BUSINESS_UNIT:
            return {
                ...state,
                businessUnit: action.payload.businessUnit
            };
        default:
            return { ...state };
    }
}

export default main;