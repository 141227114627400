import { CampaignOnboardActivateData, CampaignOnboardActivateResult, CampaignOnboardInitData, CampaignOnboardInitResult, CampaignOnboardSetPasswordData, CampaignOnboardSetPasswordResult } from './gdp-iam-backend/models'

import axios from 'axios';

export class CampaignOnboardApi {
  async init(
    data: CampaignOnboardInitData
  ): Promise<CampaignOnboardInitResult | null> {
    return axios
      .post(
        '/auth/api/campaign-onboard/init',
        data,
        {
          headers: {
            "Content-Type": "application/json"
          }
        })
      .then(response => response.data)
  }

  async setPassword(
    data: CampaignOnboardSetPasswordData
  ): Promise<CampaignOnboardSetPasswordResult | null> {
    return await axios
      .post(
        '/auth/api/campaign-onboard/set-password',
        data,
        {
          headers: {
            "Content-Type": "application/json"
          }
        })
      .then(response => response.data)
  }

  async activate(
    data: CampaignOnboardActivateData
  ): Promise<CampaignOnboardActivateResult | null> {
    return axios
      .post(
        '/auth/api/campaign-onboard/activate',
        data,
        {
          headers: {
            "Content-Type": "application/json"
          }
        })
      .then(response => response.data)
  }
}
