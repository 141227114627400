import './tailwindy.css'
import './activated.css'

import { Col, Container, Row } from 'react-bootstrap'

import { HeaderText } from 'src/Components'
import { Link } from 'react-router-dom'
import React from 'react'
import { ZrIcon } from '@zurich/web-components/react/icon'
import { ZrLogo } from '@zurich/web-components/react/logo'

const logo = require('../../Assets/activated-header.webp')

function CampaignOnboardActivated() {
    return (
        <Container>
            <div className="position-relative">
                <img src={logo} className="login-header-picture" alt="logo" />
                <div className="position-absolute w-100 d-flex align-items-center justify-content-between zurich-login-logo-container">
                    <ZrLogo
                        config="inline"
                        custom-str="fill:#ffffff;size:8rem"
                        className="zurich-login-logo"
                    />
                    <Link to="/help" className="zurich-login-icon-link">
                        <ZrIcon
                            icon="help:line"
                            custom-str="color:#ffffff;size:28px"
                            class="zurich-login-icon"
                        />
                    </Link>
                </div>
            </div>
            <Row style={{ marginTop: '3em' }}>
                <Col>
                    <HeaderText text="Zurich Access activated"/>

                    <div className="align-items-center">
                        <p>You can now use these credentials to log in to select Zurich apps and services.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default CampaignOnboardActivated
