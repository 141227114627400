import './consents.css'

import { Col, Container, Row } from 'react-bootstrap'
import { HeaderText, IconHeaderWithNavigation } from 'src/Components';

import React from 'react'
import { ZrButton } from '@zurich/web-components/react/button';
import { ZrCheckbox } from '@zurich/web-components/react/checkbox';
import { ZrPictogram } from '@zurich/web-components/react/pictogram';
import { ZrRadioSelect } from '@zurich/web-components/react/radio-select';
import { useNavigate } from 'react-router-dom'

function CampaignOnboardConsents() {
    const navigate = useNavigate();


    function handleClick(e) {
        e.preventDefault();
        navigate('/campaign-onboard/activated')
    }

    return (
        <Container>
            <IconHeaderWithNavigation />
            <Row className="pictogram-container">
                <ZrPictogram pictogram="digital-lock:dark" />
            </Row>
            <Row>
                <Col className="consents-page">
                    <HeaderText text="Terms & Conditions"/>
                    <p>Before using Zurich One, please read and accept our Terms & Conditions and our Privacy Policy.
                        You can also choose to let us reach out to you with surveys and offers occasionally.</p>

                    <div className="checkboxes">
                        <div>
                            <ZrCheckbox checked={true} /> I accept the <a href="#">Terms & Conditions</a>
                        </div>
                        <div>
                            <ZrCheckbox checked={true} /> I accept the <a href="#">Privacy Policy</a>
                        </div>
                    </div>

                    <p>I would like to be informed about current topics and products of Zurich Group in Switzerland.
                        Please keep me informed of any new developments by electronic means in future.
                        I can revoke my consent at any time.</p>

                    <div className="radioboxes">
                        <ZrRadioSelect config="inline" name="radio-select" model="yes">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </ZrRadioSelect>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col style={{ paddingTop: '2.2em' }}>
                    <ZrButton 
                        onClick={handleClick} 
                        wide={true} 
                    >
                        Continue
                    </ZrButton>
                </Col>
            </Row>
        </Container>
    )
}

export default CampaignOnboardConsents
