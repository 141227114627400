import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CampaignOnboardApi } from 'src/services';
import { CampaignOnboardInitData } from 'src/services/gdp-iam-backend';
import { WLoader } from 'src/Components/wrappers';
import { ZrToast } from '@zurich/web-components/react/toast'
import { connect } from 'react-redux';
import { sendCampaignOnboardInit } from 'src/Utils/redux/actions/main';

type CampaignOnboardProps = {
    sendCampaignOnboardInit(value: any): void
}


function CampaignOnboard({
    sendCampaignOnboardInit
}: CampaignOnboardProps) {
    const [searchParams, _] = useSearchParams();
    const [pageError, setPageError] = useState<string | undefined>('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        async function init() {
            const clientId = searchParams.get('clientId')
            const customerOnboardRequestId = searchParams.get('customerOnboardRequestId')
    
            if (!clientId) {
                setPageError("The 'clientId' is not specified.");
                return;
            }
    
            if (!customerOnboardRequestId) {
                setPageError("The 'customerOnboardRequestId' is not specified.");
                return;
            }
      
            const data: CampaignOnboardInitData = {
                clientId,
                customerOnboardRequestId
            }

            setLoading(true)
            try {
                const result = await new CampaignOnboardApi().init(data)
                if (result !== null) {
                    sendCampaignOnboardInit({
                        clientId,
                        customerOnboardRequestId,
                        passwordComplexity: result.passwordComplexity
                    })
                    navigate('/campaign-onboard/set-password')
                }
            }
            catch (error: any) {
                setPageError(error.response.data.message)
                return
            }
            finally {
                setLoading(false)
            }
        }

        init()
    }, [])

    if (pageError) {
        return <ZrToast content={pageError} config="negative" />
    }
    
    return <WLoader loading={loading} />
}


const mapStateToProps = state => ({
})

const mapDispatchToProps = {
    sendCampaignOnboardInit
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignOnboard);
