import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ZrLogo } from '@zurich/web-components/react/logo';
import { ZrIcon } from '@zurich/web-components/react/icon';
import styles from './IconHeaderWithNavigaton.module.css'

function IconHeader() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className={`w-100 d-flex align-items-center justify-content-between ${styles.zurichLogoContainer}`}>
            <div onClick={goBack} style={{ cursor: 'pointer' }}>
                <ZrIcon
                    icon="arrow-left:line"
                    class={styles.zurichHeaderIcon}
                />
            </div>
            <ZrLogo
                config="inline"
                class={styles.zurichHeaderLogo}
            />
            <Link to="/help" className={styles.zurichHeaderIconLink}>
                <ZrIcon
                    icon="phone:line"
                    class={styles.zurichHeaderIcon}
                />
            </Link>
        </div>
    )
}

export default IconHeader;