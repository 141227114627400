import "./set-password.css";

import { CampaignOnboardState, MainState } from "src/Utils/redux/reducers/main";
import { Col, Container, Row } from "react-bootstrap";
import { HeaderText, IconHeaderWithNavigation } from "src/Components";
import React, { useEffect, useState } from "react";
import { sendStateTokenData, sendUserId } from "src/Utils/redux/actions/main";

import { CampaignOnboardApi } from "src/services";
import { CampaignOnboardSetPasswordData } from "src/services/gdp-iam-backend";
import PasswordPolicy from "src/Components/PasswordPolicy";
import { WLoader } from "src/Components/wrappers";
import { ZrButton } from "@zurich/web-components/react/button";
import { ZrPasswordInput } from "@zurich/web-components/react/password-input";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

type CampaignOnboardSetPasswordProps = {
    context: CampaignOnboardState
    stateinfo?: MainState
    sendUserId(value: any): void
    sendStateTokenData(value: any): void
}

// The idea is for this to be the only component to handle password updates. For now they will remain separate.
const regExp = RegExp(
  /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?\d)(?=\S*[^a-zA-Z0-9_\s]).{8,})\S$/
); 
function CampaignOnboardSetPassword({
  context,
  stateinfo
}: CampaignOnboardSetPasswordProps) {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [iserror, setIsError] = useState({
    Password: ''
  });
  const [isempty, setIsempty] = useState<{
    Password: string | null
  }>({
    Password: 'empty'
});
  const [passwordType, setPasswordType] = useState({
    "Password": "password",
    "ConfirmPassword": "password"
  });
  const navigate = useNavigate();
  const { t } = useTranslation(stateinfo!.i18nConfiguration.namespace);

  useEffect(() => {
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    setHasError(false);

    if (password !== passwordConfirm) {
      return;
    }

    setLoading(true);
    const data: CampaignOnboardSetPasswordData = {
      newPassword: password
    }
    try {
      const result = await new CampaignOnboardApi().setPassword(data);
      if (result && result.contactValue) {
        const contactValue = encodeURIComponent(result.contactValue)
        switch (result.contactType) {
          case 'sms':
            navigate(`/campaign-onboard/verify-sms?contactValue=${contactValue}`)
            break;
          default:
            navigate(`/campaign-onboard/verify-email?contactValue=${contactValue}`)
            break;
        }
      }
    }
    catch (err) {
      setHasError(true);
      console.error(err);
    }
    finally {
      setLoading(false);
    }
  };

  const handleValidationUpdate = (isValid) => {
    setPasswordIsValid(isValid);
  };

  const handleInputChange = (name) => (value) => {
    name === "Password" ? setPassword(value) : setPasswordConfirm(value);
    setIsError(current => {return{...current}});
    
    switch(name){
        case "Password":
            setPassword(value);
            isempty.Password = value.length === 0 ? "empty" : null;
            iserror.Password = regExp.test(value) || value.length > 6
              ?  ""
              : "Password is invalid";
            break;
        default:
            break;
    }
    setIsError({
        ...iserror
    })
  };

  return (
    <Container>
        <IconHeaderWithNavigation />
        <Row>
          <Col>
            <HeaderText text={t("setPassword.header")}/>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <WLoader loading={loading} />
              <div className="forms--shape">
                <ZrPasswordInput
                    id="field-input-1"
                    config="shaped"
                    name="randomField1"
                    label={t("resetPassword.passwordLabel") as string}
                    disabled={loading}
                    onChange={handleInputChange('Password')}
                    invalid={(iserror.Password || "").length > 0}
                    help-text={(iserror.Password || "").length > 0 ? iserror.Password : ""}
                />           
              </div>
              <div className="forms--shape">
                <ZrPasswordInput
                    id="field-input-2"
                    config="shaped"
                    name="randomField2"
                    label={t("resetPassword.passwordConfirmationLabel") as string}
                    disabled={!loading && (isempty.Password === 'empty' || (iserror.Password || "").length > 0)}
                    onChange={handleInputChange('PasswordConfirm')}
                    invalid={isempty.Password !== 'empty' && password !== passwordConfirm}
                    help-text={isempty.Password !== 'empty' && password !== passwordConfirm ? t("resetPassword.passwordDifferentMessage") as string : ""}
                />
                <span
                  className="invalid-feedback"
                  style={{ display: hasError ? "block" : "none" }}>
                  {t("resetPassword.passwordChangeError")}
                </span>
              </div>
              <PasswordPolicy policies={context.passwordComplexity} password={password} onValidationUpdate={handleValidationUpdate}/>           
          </Col>
        </Row>
        <Row className="mt-auto set-password-row">
          <Col className="d-flex flex-column align-items-center">
            <ZrButton 
              onClick={handleClick} 
              wide={true} 
              disabled={loading || password !== passwordConfirm || !passwordIsValid}>
                {t("setPassword.setPass") as string}
            </ZrButton>
          </Col>
        </Row>
      </Container>
    );
  
}

const mapStateToProps = state => ({
    context: state.main.campaignOnboard,
    stateinfo: state.main
})

const mapDispatchToProps = {
    sendUserId,
    sendStateTokenData,
  }

export default connect(mapStateToProps, mapDispatchToProps)(CampaignOnboardSetPassword)
