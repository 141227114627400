export const SET_STATETOKEN = "SET_STATETOKEN";
export const SET_FACTORID = "SET_FACTORID";
export const SET_CONTACT_VALUE = "SET_CONTACT_VALUE";
export const SET_OTP_CODE="SET_OTP_CODE";
export const SET_AUTH_REQ="SET_AUTH_REQ";
export const SET_USER_ID="SET_USER_ID";
export const SET_ONBOARD_STEP="SET_ONBOARD_STEP";
export const SET_I18N_CONFIGURATION="SET_I18N_CONFIGURATION";
export const SET_SELF_ONBOARD_DATA = 'SET_SELF_ONBOARD_DATA';
export const SET_CAMPAIGN_ONBOARD_INIT = "SET_CAMPAIGN_ONBOARD_INIT"
export const SET_BUSINESS_UNIT = 'SET_BUSINESS_UNIT';