import { ApiLoginBody, ApiVerifyBody, GetAuthLoginResult, LoginResponse, VerifyResponse, GetByClientIdResult, SelfOnboardData, SelfOnboardResult, SelfOnboardTriggerVerificationData, SelfOnboardVerifyData } from './gdp-iam-backend/models'

import axios from 'axios';

export class GdpIamBackend {

  //LoginController

  async getAuthLogin(clientId: string): Promise<GetAuthLoginResult | null> {
    return axios
      .get(`/auth/api/auth-login?clientId=${clientId}`,
        {
          headers: { "Content-Type": "application/json" }
        })
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        return null
      })
  }

  Login(data: ApiLoginBody): Promise<LoginResponse> {
    return axios
      .post('/auth/api/login', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  Verify(data: ApiVerifyBody): Promise<VerifyResponse> {
    return axios
      .post('/auth/api/verify', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  //ClientController

  async getByClientId(clientId: string): Promise<GetByClientIdResult | null> {
    return axios
      .get(`/auth/clients/${clientId}`,
        {
          headers: { "Content-Type": "application/json" }
        })
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  }

  //SelfOnboardController

  async userSelfOnboard(
    selfOnboardData: SelfOnboardData
  ): Promise<SelfOnboardResult | null> {
    return axios
      .post(`/auth/api/self-onboard`, selfOnboardData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => response.data)
      .catch((error) => {
        console.error('Self onboard failed:', error);
        return null;
      });
  }

  async triggerContactVerification(
    triggerVerificationData: SelfOnboardTriggerVerificationData
  ): Promise<SelfOnboardResult | null> {
    return axios
      .post(`/auth/api/self-onboard/trigger-verification`, triggerVerificationData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => response.data)
      .catch((error) => {
        console.error('Trigger contact verification failed:', error);
        return null;
      });
  }

  async verifyContact(
    verifyData: SelfOnboardVerifyData
  ): Promise<SelfOnboardResult | null> {
    return axios
      .post(`/auth/api/self-onboard/verify`, verifyData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => response.data)
      .catch((error) => {
        console.error('Verify contact failed:', error);
        return null;
      });
  }

}
