export const Contacts = {
  IE_GI_Zurich_Ireland: {
    ServiceDeskPhone: '+353 012092222',
    ServiceDeskEmail: 'broker.service.desk@zurich.com'
  },
  CH_GL_Zurich_Schweiz: {
    ServiceDeskPhone: '',
    ServiceDeskEmail: ''
  },
  ES_GI_Zurich_Spain: {
    ServiceDeskPhone: '',
    ServiceDeskEmail: ''
  }
};