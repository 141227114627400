import "./SelfOnboard.css";
import { Col, Container, Row } from "react-bootstrap";
import { IconHeaderWithNavigation, PageHeader, Spinner } from "src/Components";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainState } from "src/Utils/redux/reducers/main";
import { ZrTextInput } from "@zurich/web-components/react/text-input";
import { ZrButton } from "@zurich/web-components/react/button";
import { GdpIamBackend } from "src/services";

const regExpEmail = RegExp(
    /^(([^<>()[$\\.,;:\s@"]+(\.[^<>()[$\\.,;:\s@"]+)*)|(".+"))@(($[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$)|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const regExpPhone = RegExp(/^\d{6,10}$/);

type SelfOnboardPersonalDataProps = {
    stateinfo: MainState;
};

const gdpIamBackendService = new GdpIamBackend();

function SelfOnboardPersonalData({ stateinfo }: SelfOnboardPersonalDataProps) {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const clientId = searchParams.get("client_id");
    const { t } = useTranslation(stateinfo.i18nConfiguration.namespace);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState("+");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errors, setErrors] = useState({
        email: "",
        phoneNumber: "",
    });
    const [pageError, setPageError] = useState("");
    const [app, setApp] = useState("");

    useEffect(() => {
        async function init() {
            try {
                if (!clientId) {
                    setPageError("The 'client_id' is not specified.");
                    return;
                }

                const result = await gdpIamBackendService.getByClientId(
                    clientId
                );
                if (!result) {
                    setPageError(`Unknown client '${clientId}'.`);
                    return;
                }

                dispatch({
                    type: "SET_BUSINESS_UNIT",
                    payload: { businessUnit: result.businessUnit as string },
                });
                setApp(result.originatorApp as string);
            } catch (err) {
                setPageError(`Error fetching client: ${clientId}`);
            }
        }
        init();
    }, []);

    const handleInputChange = (name: string) => (value: string) => {
        if (name === "email") {
            setEmail(value);
            setErrors((prev) => ({
                ...prev,
                email: !regExpEmail.test(value)
                    ? t("selfOnboard.emailError")
                    : "",
            }));
        } else if (name === "countryCode") {
            let newValue = value;
            if (!newValue.startsWith("+")) {
                newValue = "+" + newValue.replace(/\D/g, "");
            } else {
                newValue = "+" + newValue.slice(1).replace(/\D/g, "");
            }
            setCountryCode(newValue);
        } else if (name === "phoneNumber") {
            const cleanValue = value.replace(/\D/g, "");
            setPhoneNumber(cleanValue);
            setErrors((prev) => ({
                ...prev,
                phoneNumber: !regExpPhone.test(cleanValue)
                    ? t("selfOnboard.mobileError")
                    : "",
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const data = {
                email: email,
                sms: `${countryCode}${phoneNumber}`,
                app: app,
                businessUnit: stateinfo.businessUnit,
            };
            const onboardResult = await gdpIamBackendService.userSelfOnboard(
                data
            );

            if (
                onboardResult &&
                onboardResult.stateToken &&
                onboardResult.status === "CONFIRM_CONTACT"
            ) {
                const emailVerificationRequest = {
                    stateToken: onboardResult.stateToken,
                    contactType: "EMAIL",
                    contactValue: email,
                    app: app,
                    businessUnit: stateinfo.businessUnit,
                };

                const triggerVerifyResult =
                    await gdpIamBackendService.triggerContactVerification(
                        emailVerificationRequest
                    );

                if (
                    triggerVerifyResult &&
                    triggerVerifyResult.stateToken &&
                    triggerVerifyResult.status === "VERIFY_CONTACT"
                ) {
                    
                    dispatch({
                        type: "SET_SELF_ONBOARD_DATA",
                        payload: {
                            stateToken: triggerVerifyResult.stateToken,
                            email,
                            phoneNumber: `${countryCode}${phoneNumber}`,
                            app,
                        },
                    });

                    navigate(
                        `/self-onboard/verify-email?client_id=${clientId}`
                    );
                }
            }
        } catch (err) {
            console.error(err);
            setErrors((prev) => ({
                ...prev,
                email: t("selfOnboard.submitError"),
            }));
        } finally {
            setIsLoading(false);
        }
    };

    const isValid =
        !errors.email &&
        !errors.phoneNumber &&
        email &&
        countryCode &&
        phoneNumber;

    if (pageError) {
        return <div>Error: {pageError}</div>;
    }

    return (
        <Container>
            <IconHeaderWithNavigation />
            <Row>
                <Col>
                    <PageHeader>{t("selfOnboard.header")}</PageHeader>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <p className="header-description">
                        {t("selfOnboard.headerDescription")}
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    {isLoading && <Spinner />}
                    <form
                        onSubmit={handleSubmit}
                        className="form-group-self-onboard"
                    >
                        <div className="forms--shape">
                            <ZrTextInput
                                id="email-input"
                                config="shaped"
                                name="email"
                                label={t("selfOnboard.emailLabel") as string}
                                model={email}
                                onChange={handleInputChange("email")}
                                invalid={Boolean(errors.email)}
                                help-text={errors.email}
                            />
                        </div>
                        <div className="forms--shape phone-input-group">
                            <div className="country-code-wrapper">
                                <ZrTextInput
                                    id="country-code-input"
                                    config="shaped"
                                    name="countryCode"
                                    label="Prefix"
                                    model={countryCode}
                                    onChange={handleInputChange("countryCode")}
                                />
                            </div>
                            <ZrTextInput
                                id="phone-input"
                                config="shaped"
                                name="phoneNumber"
                                label="Mobile number"
                                model={phoneNumber}
                                onChange={handleInputChange("phoneNumber")}
                                invalid={Boolean(errors.phoneNumber)}
                                help-text={errors.phoneNumber}
                            />
                        </div>
                        <ZrButton
                            onClick={handleSubmit}
                            disabled={!isValid || isLoading}
                            wide={true}
                        >
                            {t("selfOnboard.continue") as string}
                        </ZrButton>
                    </form>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state: any) => ({
    stateinfo: state.main,
});

export default connect(mapStateToProps)(SelfOnboardPersonalData);
