import { ZrIcon } from '@zurich/web-components/react/icon';
import './PasswordPolicy.css';
import React from 'react';
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import policyRules from 'src/Utils/passwordPolicies';

type PasswordPolicyProps = {
    password: string;
    policies: any;
    onValidationUpdate(isValid: boolean): void;
    i18nNamespace: string;
};

const PasswordPolicy = (props: PasswordPolicyProps) => {
    const { i18nNamespace } = props;
    const { t } = useTranslation(i18nNamespace);
    const validationResults: any[] = [];

    for (const prop in props.policies) {
        const policyRule = policyRules.get(prop);
        if(!policyRule){
            continue;
        }
        validationResults.push({
            message: t(policyRule.translation, {
                num: props.policies[prop]
            }),
            isValid: policyRule.validator(props.password, props.policies[prop])
        });
    }

    const isValid = validationResults.filter(v => v.isValid).length === validationResults.length

    props.onValidationUpdate(isValid);

    if (validationResults.length > 0) {
        return (
            <div className="validation-results">
                <p className="pass-policy-description">{t('passwordPolicy.Description')}</p>
                <ul className="ul-validation-results">
                    {
                        validationResults.map(policy => {
                            return (
                                <li className="li-alignment" key={policy.message}>
                                    {policy.isValid ? 
                                        <ZrIcon icon="check" class="pass-policy-check"></ZrIcon> :
                                        <ZrIcon icon="dot" class="pass-policy-dot"></ZrIcon>}                             
                                    <b>{policy.message}</b></li>
                            ) 
                        })
                    }
                </ul>
            </div>
        );
    }

    return null;
};

const mapStateToProps = (state) => ({
    i18nNamespace: state.main.i18nConfiguration.namespace
});

export default connect(mapStateToProps)(PasswordPolicy);