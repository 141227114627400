import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import mainReducer from "./main";

const mainConfiguration = {
    key: "main",
    storage,
    whitelist: [
        'i18nConfiguration',
        'selfOnboard',
        'campaignOnboard',
        'businessUnit'
    ]
}

const persistedReducer = persistReducer(
    mainConfiguration,
    mainReducer);

const rootReducer = combineReducers({
    main: persistedReducer
});

export default rootReducer;