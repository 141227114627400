import "./Help.css";
import { Contacts } from "../../configuration";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

type HelpProps = {
    i18nNamespace: string;
    businessUnit?: string;
};

function Help(props: HelpProps) {
    const { i18nNamespace, businessUnit } = props;
    const { t } = useTranslation(i18nNamespace);

    const getMessage = (): string => {
        switch (true) {
            case businessUnit === "IE_GI_Zurich_Ireland" ||
                i18nNamespace === "ireland":
                return (
                    t("help.message", {
                        phone: Contacts.IE_GI_Zurich_Ireland.ServiceDeskPhone,
                        email: Contacts.IE_GI_Zurich_Ireland.ServiceDeskEmail,
                    }) || ""
                );

            case businessUnit === "CH_GL_Zurich_Schweiz":
                return t("help.defaultMessage");

            case businessUnit === "ES_GI_Zurich_Spain":
                return t("help.defaultMessage");

            default:
                return t("help.defaultMessage");
        }
    };

    return (
        <div className="help-body">
            <div
                dangerouslySetInnerHTML={{
                    __html: getMessage(),
                }}
            />
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    i18nNamespace: state.main.i18nConfiguration.namespace,
    businessUnit: state.main.businessUnit,
});

export default connect(mapStateToProps)(Help);
