import * as t from "../types";

export const sendStateTokenData = (allInfo) => dispatch => dispatch({
    type: t.SET_STATETOKEN,
    payload: allInfo
});

export const sendFactorIdData = (allInfo) => dispatch => dispatch({
    type: t.SET_FACTORID,
    payload: allInfo
});

export const sendContactValueData = (allInfo) => dispatch => dispatch({
    type: t.SET_CONTACT_VALUE,
    payload: allInfo
});

export const sendOTP = (allinfo) => dispatch => dispatch({
    type: t.SET_OTP_CODE,
    payload: allinfo
});

export const sendAuthnRequest = (allinfo) => dispatch => dispatch({
    type: t.SET_AUTH_REQ,
    payload: allinfo
});

export const sendUserId = (allinfo) => dispatch => dispatch({
    type: t.SET_USER_ID,
    payload: allinfo
});

export const sendOnboardStep = (allinfo) => dispatch => dispatch({
    type: t.SET_ONBOARD_STEP,
    payload: allinfo
});

export const sendI18nConfiguration = (allinfo) => dispatch => dispatch({
    type: t.SET_I18N_CONFIGURATION,
    payload: allinfo
});

export const setSelfOnboardData = (data) => dispatch => dispatch({
    type: t.SET_SELF_ONBOARD_DATA,
    payload: data
});

export const sendCampaignOnboardInit = (allinfo) => dispatch => dispatch({
    type: t.SET_CAMPAIGN_ONBOARD_INIT,
    payload: allinfo
});

export const setBusinessUnit = (businessUnit: string) => ({
    type: t.SET_BUSINESS_UNIT,
    payload: businessUnit
});