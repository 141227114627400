import React from 'react';
import './HeaderText.css'

type HeaderTextProps = {
    text: string;
};

function HeaderText({ text }: HeaderTextProps): React.JSX.Element | null {
    return <h3 className='header-text'>{text}</h3>;
}

export default HeaderText;