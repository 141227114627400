import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ZrButton } from "@zurich/web-components/react/button";
import { ZrTextInput } from "@zurich/web-components/react/text-input";
import { ZrPictogram } from "@zurich/web-components/react/pictogram";
import { IconHeaderWithNavigation } from "src/Components";
import { WLoader } from "src/Components/wrappers";
import { GdpIamBackend } from "src/services";

import "./SelfOnboard.css";
import { MainState } from "src/Utils/redux/reducers/main";

interface SelfOnboardVerificationProps {
    stateinfo: MainState;
    verificationType: "email" | "sms";
}

function SelfOnboardVerification({
    stateinfo,
    verificationType,
}: SelfOnboardVerificationProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const clientId = searchParams.get("client_id");
    const [err, setErrors] = useState("");
    const [pageError] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [iserror, setIsError] = useState<string | null>(
        "At least 6 characters required"
    );
    const [message, setMessage] = useState("");

    const emailVerification = verificationType === "email";
    const gdpIamBackendService = new GdpIamBackend();

    const header = emailVerification
        ? t("description.VerificationPromptHeaderEmail")
        : t("description.VerificationPromptHeaderPhone");

    const headerMfaType = emailVerification
        ? t("description.VerificationPromptHeaderEmailType")
        : t("description.VerificationPromptHeaderPhoneType");

    const combinedHeader = `${header} ${headerMfaType}`;

    function maskContact(contactValue: string) {
        if (emailVerification) {
            const [localPart, domainPart] = contactValue.split("@");
            if (localPart.length < 6) {
                return contactValue;
            }
            const maskedLocalPart =
                localPart.slice(0, 4) + "*".repeat(localPart.length - 4);
            return `${maskedLocalPart}@${domainPart}`;
        } else {
            if (contactValue.length < 6) {
                return contactValue;
            }
            const numStars = contactValue.length - 6;
            return (
                contactValue.slice(0, 4) +
                "*".repeat(numStars) +
                contactValue.slice(-2)
            );
        }
    }

    useEffect(() => {
        {
            setConfirmationCode("");
            setErrors("");
            setMessage("");
            setIsError("At least 6 characters required");

            const clientId = searchParams.get("client_id");
            if (stateinfo.selfOnboard.stateToken === "") {
                navigate(`/self-onboard?client_id=${clientId}`);
            }
        }
    }, [verificationType]);

    const handleInputChange = (value: string) => {
        if (value.length >= 6) {
            setConfirmationCode(value);
            setIsError(null);
        } else {
            setIsError("At least 6 characters required");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors("");

        try {
            const verifyRequest = {
                stateToken: stateinfo.selfOnboard.stateToken,
                contactType: emailVerification ? "EMAIL" : "SMS",
                otp: confirmationCode,
                app: stateinfo.selfOnboard.app,
                businessUnit: stateinfo.businessUnit,
            };

            const verifyResult = await gdpIamBackendService.verifyContact(
                verifyRequest
            );

            if (
                verifyResult &&
                verifyResult.stateToken &&
                verifyResult.status === "CONTACT_VERIFIED"
            ) {
                if (emailVerification) {
                    const smsVerificationRequest = {
                        stateToken: verifyResult.stateToken,
                        contactType: "SMS",
                        contactValue: stateinfo.selfOnboard.phoneNumber,
                        businessUnit: stateinfo.businessUnit,
                        app: stateinfo.selfOnboard.app,
                    };

                    const triggerVerifyResult =
                        await gdpIamBackendService.triggerContactVerification(
                            smsVerificationRequest
                        );

                    if (
                        triggerVerifyResult &&
                        triggerVerifyResult.stateToken &&
                        triggerVerifyResult.status === "VERIFY_CONTACT"
                    ) {
                        dispatch({
                            type: "SET_SELF_ONBOARD_DATA",
                            payload: {
                                stateToken: triggerVerifyResult.stateToken,
                                email: stateinfo.selfOnboard.email,
                                phoneNumber: stateinfo.selfOnboard.phoneNumber,
                                businessUnit: stateinfo.businessUnit,
                                app: stateinfo.selfOnboard.app,
                            },
                        });

                        navigate(
                            `/self-onboard/verify-sms?client_id=${clientId}`
                        );
                    }
                } else {
                    navigate(`/self-onboard?client_id=${clientId}`);
                }
            } else {
                setErrors("Verification failed");
                setMessage(t("Verification.WrongCode") as string);
            }
        } catch (err) {
            console.error(err);
            setMessage(t("description.ActivationErrorText") as string);
        } finally {
            setIsLoading(false);
        }
    };

    if (pageError) {
        return <div>Error: {pageError}</div>;
    }

    return (
        <div className="verification-box">
            <Container>
                <IconHeaderWithNavigation />
                <Row className="pictogram-container">
                    {emailVerification ? (
                        <ZrPictogram pictogram="mail:dark" />
                    ) : (
                        <ZrPictogram pictogram="mobile-call:dark" />
                    )}
                </Row>
                <Row>
                    <Col>
                        <h3 className="verify-header">{combinedHeader}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="verification-prompt-sub">
                            {t("description.VerificationResponse1")}
                            <b>
                                {maskContact(
                                    emailVerification
                                        ? stateinfo.selfOnboard.email
                                        : stateinfo.selfOnboard.phoneNumber
                                )}
                            </b>
                            {t("description.VerificationResponse2")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="forms--shape">
                            <ZrTextInput
                                id="field-input"
                                config="shaped"
                                name="ConfirmationCode"
                                label={
                                    t("Verification.confirmationCode") as string
                                }
                                onChange={handleInputChange}
                                model={confirmationCode}
                            />
                        </div>
                        <div className="message">
                            {err.length > 0 ? (
                                <p className="verification-error">{message}</p>
                            ) : null}
                        </div>
                        <div className="forms--shape">
                            <ZrButton
                                onClick={handleSubmit}
                                wide={true}
                                disabled={iserror !== null || isLoading}
                            >
                                {header}
                            </ZrButton>
                        </div>
                        <WLoader loading={isLoading} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    stateinfo: state.main,
});

export default connect(mapStateToProps)(SelfOnboardVerification);
